<template>
    <div>
        <label class="mb-2">{{ placeholder }}</label>
        <input 
            :id="id"
            :name="name"
            :class="mode"
            :type="type"
            :required="required"
            :maxlength="maxlength"
            v-model="model"
        >
    </div>
</template>
  
<script setup>

    // Define props
    const props = defineProps({
        id: String,
        mode: String,
        name: String,
        placeholder: String,
        type: String,
        value: String,
        required: Boolean,
        maxlength: Number
    });

    // Define emits
    const emit = defineEmits(['input']);

    // Reactive state for model
    const model = ref(props.value);

    // Watch for changes in model and emit input event
    watch(model, (newValue) => {
        console.log(newValue,'newValue')
        emit('input', newValue);
    });
</script>


<style scoped>

input.invalid {
    border-color: #fc7979 !important;
}
input{
    font-size: 16px;
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
    border-radius: 4px;
}
input::placeholder{
    font-size: 14px;
    color:  #363030;
    font-family: 'NY600';
}
label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
    text-transform: capitalize;
}
.disabled input {
    pointer-events: none;
    background: #ededed;
}
</style>
